import React, { Component } from "react"
import { navigate, Link } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import { retrieveAllOrders, fetchUserDataByUid, retrieveAllUsers } from "api/db"

class Admin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      authorized: true,
      orders: null,
    }
  }

  componentDidMount() {
    if (
      JSON.parse(localStorage.getItem("uid")) !== "HvIOG2fJjkSmLe9MCIVEecTl8IR2"
    ) {
      this.setState({
        authorized: false,
      })
    }

    retrieveAllOrders().then(snapshot => {
      let orders = snapshot.val().reverse()
      this.setState({
        orders: orders,
      })
    })

    retrieveAllUsers().then(snapshot => {
      let users = snapshot.val()
      this.setState({
        users: Object.values(users),
      })
    })
  }

  render() {
    let admin
    if (this.state.authorized == false) {
      admin = <p> Not Authorized </p>
    } else
      admin = (
        <main>
          <section className="hero is-info welcome is-small">
            <div className="hero-body">
              <div className="container">
                <h1 className="title">Hello, Tick Tock Admin.</h1>
              </div>
            </div>
          </section>{" "}
          <br />
          <div className="container">
            <h2 className="is-size-3">
              <b>All Orders</b>{" "}
            </h2>
            <br />

            <table className="table is-bordered is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Date</th>
                  <th>Submitted?</th>
                  <th>Company Name</th>
                  <th>Company Location</th>
                  <th>Total Cases</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              {this.state.orders ? (
                <tbody>
                  {this.state.orders.map(order => {
                    let uid = order.uid
                    let storeName = ""
                    let storeLocation = ""

                    fetchUserDataByUid(uid).on("value", function(snapshot) {
                      let data = snapshot.val()
                      storeName = data.companyName
                      storeLocation =
                        data.companyCity + ", " + data.companyState
                    })
                    return (
                      <tr key={order.dateUpdated}>
                        <th>
                          <Link to={`/orders/${order.orderNumber}`}>
                            {order.orderNumber}
                          </Link>
                        </th>
                        <th>{order.dateUpdated}</th>
                        <th>
                          {order.totalPrice ? (
                            <span className="button is-success is-rounded is-small">
                              yes
                            </span>
                          ) : (
                            <span className="button is-danger is-rounded is-small">
                              no
                            </span>
                          )}
                        </th>
                        <th>{storeName}</th>
                        <th>{storeLocation}</th>

                        <th>{order.totalCases}</th>
                        <th>
                          {order.totalPrice ? (
                            <p>${Math.round(order.totalPrice)}</p>
                          ) : null}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                "Loading..."
              )}
            </table>
            <br />
            <h2 className="is-size-3">
              <b>All Customers</b>
            </h2>
            <br />

            <table className="table is-bordered is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Phone</th>
                  <th>Nature</th>
                  <th>Admin Name</th>
                  <th>Admin Position</th>
                  <th>Tax ID</th>
                  <th>Registered Date</th>
                </tr>
              </thead>
              {this.state.users ? (
                <tbody>
                  {this.state.users
                    .sort(function(a, b) {
                      a = new Date(a.createdAt)
                      b = new Date(b.createdAt)
                      return a > b ? -1 : a < b ? 1 : 0
                    })
                    .map(user => {
                      return (
                        <tr key={user.uid}>
                          <th>{user.companyName}</th>
                          <th>
                            {user.companyAddress} <br />
                            {user.companyCity +
                              ", " +
                              user.companyState +
                              " " +
                              user.companyZipCode}
                          </th>
                          <th>
                            Phone:{" "}
                            {user.companyPhoneNumber
                              .replace(/\D+/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                            <br />
                            <br />
                            Fax:{" "}
                            {user.companyFaxNumber
                              .replace(/\D+/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                            <br />
                            <br />
                            Cell:{" "}
                            {user.userCellNumber
                              .replace(/\D+/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}

                              <br/>
                          </th>
                          <th>{user.natureOfBusiness}</th>
                          <th>{user.userName}</th>
                          <th>{user.userPosition}</th>
                          <th>
                            <small>{user.companyTaxID}</small>
                          </th>
                          <th>
                            <small>{user.createdAt}</small>
                          </th>
                        </tr>
                      )
                    })}
                </tbody>
              ) : (
                "Loading..."
              )}
            </table>
          </div>
        </main>
      )

    return (
      <Layout>
        <SEO title="Admin | Tick Tock Inc." />
        {admin}
      </Layout>
    )
  }
}

export default Admin

// userCount, orderCount

// X new orders in past 24 hours

// all customers table

// all orders -- click to expand a table!!
